import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src750413537/src/src/layout/defaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Grid = makeShortcode("Grid");
const Text = makeShortcode("Text");
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Section mdxType="Section">
      <h1 {...{
        "id": "imagery"
      }}>{`Imagery`}</h1>
      <Grid my={4} gridTemplateColumns="repeat(3, 35rem)" gridTemplateRows="22rem" gridGap={2} mdxType="Grid">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/0a19e70cb98d371834485d390173e83f/a303f/studio-shoot.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "66.66666666666666%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAADs0lEQVQ4yyWTbUzUBRzHb6sXttlq+aL1ooXLUCCGjoRaHJEbNSESphRoiU5Ix4lAPHQ8CU0eAzmJZ5CnA47jMUDuOEXhuAeeAwLi8TAxOE8ehCjZstynv/nq9+K3ffZ9+P1EfsFS4jPzUHfeRK/XMzQ8wubdJf5evM9j0xJbs4sMG4zc6urCoNOjUaspLi7Ey8eXt23fQ3YtjzXzA5YWFnm4YkZk63oM8dET1NYqMBqNGPuMrM4v8PSemebySjTKOiaHhukXdtrbd2isVyKRhPCOnSP77Z3IzyvA8vsy9+ZNWJZXECWnpHLuwkWKikvpVKnR9vawPD3NvwKwv6aM0aofaSwr5WRAIJ7evrh7neCoxzF8vY9zwP4w2TkFrFnW+W12/jlwbWoURUUF5ZXV6Hp7uXW7i8WJCXbmFsiITeD4x664iV2x2rsPZ5cjyDKz8XJ5H2cHB0597oFclsRIpxLTr9NYnlm2TE7xYHYB3U3N/zndUKmYHxvD3D/Aa29Y8cLuVxE7OXFovzW5SVJW7y8hjw/DydaGtHQZN4pSaEqXsDg3/xxoHhlkeWIKXUcbao2GpuYmxgf62Z6cRHLqS3btfhnPg9a4O9hRkRzHyvwMxdlpeLq5kpb4PRHSZLT6Pn4eHWX1GXDJ0IPJoEPb3IhK3Umtoha9tpu/ZuYYkOdjY2NLwrcRBB35gPor0UyMTxAXep4ESTB9lbkEXwinoExBt87AutC2yDxoZFLdTle9gra2duEkihjrM/CP6S6D1fnEhEro1PaTKEy5LAOdcYikOCl1GYmMVuQRHhZLZGwKI/3DbKwIwFSplPzEeEoLi2htbSHhcizRkosMNLcw19HAT3UK5IoGlA3N9ArWOjV3UFSW0NXSSFTQOaEod/zPBGOamuHRsgWRr68/OTFRFBcVUlV1nYiIMKz2WSMWuzGkrKG1NJ8vAi+h69ExMDRGm6qLuupyNKoO3Dx8EO3aw177D/llaJw/H64jys/IRKuQU1tZTm1VCbk5WRQJaq/KrgntJRF68iveOuhCZkQI0qh4ohJS0HYLuQulefj48eIeK2wOuzM7McvO5haiS6cD0CuqaW+oRlkqQ1GYRU52FmXl5RSnp/OZ8EW27x7CydGZT7y+5sz5SEzCmfHkKX4B3yB65U0+9T7N9sYm25ubiMQH7CiJi6WuRgBcvYL8hzgyo4JICjlL5uVomlK/I9jLFU9XRyRnA/jIP5TxSUHN1haBknBEL71OZEwKTx7v8MfGI/4DhsvOWjiowigAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Rebel Kronos",
                "title": "Rebel Kronos",
                "src": "/static/0a19e70cb98d371834485d390173e83f/c1b63/studio-shoot.png",
                "srcSet": ["/static/0a19e70cb98d371834485d390173e83f/5a46d/studio-shoot.png 300w", "/static/0a19e70cb98d371834485d390173e83f/0a47e/studio-shoot.png 600w", "/static/0a19e70cb98d371834485d390173e83f/c1b63/studio-shoot.png 1200w", "/static/0a19e70cb98d371834485d390173e83f/a303f/studio-shoot.png 1733w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/1d8cff8d25b0a58f7f076c4ff1d2227e/ce2f1/masks-computer.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "66.66666666666666%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAGFua4pof/EABoQAAIDAQEAAAAAAAAAAAAAAAECAAMREjH/2gAIAQEAAQUCQiMFtZTkUw+V5z//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAACAwEAAAAAAAAAAAAAAAAAMQECESD/2gAIAQEABj8CNiy4R//EABoQAQADAQEBAAAAAAAAAAAAAAEAESFRQWH/2gAIAQEAAT8hzL2AKQKcgh297FH5yUaVWeQrdtn/2gAMAwEAAgADAAAAECDP/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAEDAQE/EIuP/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAECAQE/EKmv/8QAGxABAQEAAgMAAAAAAAAAAAAAAREAITFhcZH/2gAIAQEAAT8QsQEhPWIEqj27RwEgqixxr3hDa6dSETwfcwSVcu//2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Rebel Kronos",
                "title": "Rebel Kronos",
                "src": "/static/1d8cff8d25b0a58f7f076c4ff1d2227e/e5166/masks-computer.jpg",
                "srcSet": ["/static/1d8cff8d25b0a58f7f076c4ff1d2227e/f93b5/masks-computer.jpg 300w", "/static/1d8cff8d25b0a58f7f076c4ff1d2227e/b4294/masks-computer.jpg 600w", "/static/1d8cff8d25b0a58f7f076c4ff1d2227e/e5166/masks-computer.jpg 1200w", "/static/1d8cff8d25b0a58f7f076c4ff1d2227e/ce2f1/masks-computer.jpg 1732w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/10d14753e62dca7cd9f616f0211d266a/9fa2c/phone-woman.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "66.66666666666666%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAABAv/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAFYN4hEaH//xAAZEAADAQEBAAAAAAAAAAAAAAAAAQIxAxP/2gAIAQEAAQUCKle7Jo6Y9//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABgQAQADAQAAAAAAAAAAAAAAAAEAAhBB/9oACAEBAAY/Aoa26Of/xAAaEAADAAMBAAAAAAAAAAAAAAAAASERMUHh/9oACAEBAAE/IZgzDrvgurwc2iqtClsz/9oADAMBAAIAAwAAABB3H//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/EBn/xAAVEQEBAAAAAAAAAAAAAAAAAAARAP/aAAgBAgEBPxAZv//EABwQAQADAAIDAAAAAAAAAAAAAAEAESExUUFxwf/aAAgBAQABPxBsLB9y5bqS95WvkNeOiVkuHdPM3rdHVSQcdrc//9k=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Rebel Kronos",
                "title": "Rebel Kronos",
                "src": "/static/10d14753e62dca7cd9f616f0211d266a/e5166/phone-woman.jpg",
                "srcSet": ["/static/10d14753e62dca7cd9f616f0211d266a/f93b5/phone-woman.jpg 300w", "/static/10d14753e62dca7cd9f616f0211d266a/b4294/phone-woman.jpg 600w", "/static/10d14753e62dca7cd9f616f0211d266a/e5166/phone-woman.jpg 1200w", "/static/10d14753e62dca7cd9f616f0211d266a/9fa2c/phone-woman.jpg 1733w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </Grid>
      <Text variant="gigaBold" mdxType="Text">When choosing photos, keep in mind:</Text>
      <ul>
        <li parentName="ul">{`People must look natural, and not look posed.`}</li>
        <li parentName="ul">{`Have a mix of multicultural/age/gender/etc - either withing the photo or across multiple photos within the product page or print piece.`}</li>
        <li parentName="ul">{`People must look happy or focused on their work when possible.`}</li>
        <li parentName="ul">{`People should be working on a project, be in a store, etc. to convey a message of them being hardworking, owning a small business, or trying to get online.`}</li>
        <li parentName="ul">{`If person/people aren't working on something specific or when in doubt, then a photo of someone on a phone, laptop, or tablet will also work.`}</li>
        <li parentName="ul">{`Preferably people in common clothing (ie. no suit and tie or fancy work suits).`}</li>
        <li parentName="ul">{`No filters, no overlays, no super high or super low exposure, no black and white.`}</li>
      </ul>
      <Grid my={4} gridTemplateColumns="repeat(1, 25rem)" gridTemplateRows="1fr" mdxType="Grid">
        <p><a parentName="p" {...{
            "href": "https://www.stocksy.com/"
          }}><Button color="blueDark" size="wide" type="link" variant="solid" mdxType="Button">{`Visit Stocksy`}</Button></a></p>
      </Grid>
    </Section>
    <Section bg="blueLight" mdxType="Section">
      <h1 {...{
        "id": "imagery-hashmarkscolours"
      }}>{`Imagery Hashmarks/Colours`}</h1>
      <Grid my={4} gridTemplateColumns="repeat(1, 35rem)" gridTemplateRows="22rem" mdxType="Grid">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "720px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/87e7cd96d1563875c8967470b498d4b9/37523/smiling-woman.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "66.66666666666666%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAADuklEQVQ4yyWSe0xTdxTH75YtzcySMW3C6rYsWzLD5jJRGC6yRStTt7GNLXVEzEgGmWxkgkQEFYq1SkEE027Emf0hG1NwQWdMtBORR9+lPAaU3pY+KPTNo4VC3SLe6/3ud+tNvjknNzmffM/5/qjFpeWs5fgK4itPtEy0EFtCZGERwcgcfIEQ3NNe2J0u2OxTRA7YXW44yT/vrB+BcARzi9HkTHeP9hUqNDe/jWiNDLPByDzDyxcMMdMzs4zHO8M4XO5k7/P5GRvtYKw2O+P0TDOz/gATCEWY8PwCQ0whthyP92lMImrK7ckgQ6zLMw0yyLlJnSIOJm00rESOKSc6um6g+rQCetMgnG4PXMSdPxgGMYLIYpQjMCwtxxMavUVEabSGrKGRUXbcOskDuAlSR8fGMTQ8gvEJK86rWvHqpjTk7fkAkgMHYbQMY8bnT56CuOPX5aJPHCZ0xmERFQz60gY0OtZgNMM8aOFMZguM5kHoDSbodAZsysxGrvh9JAYuoyj/M5RUVCEQChNoIOmQB/L3I0r0a80i6qv8YvW9+71c/4AWBMwRge95Xe38Ey++9iYqC/MwfVMJ1fESpGXugJW2J13ya5Pbc+G5BR6euNujFVHZu77A2XoFTsvlnLSuDrVSKU7W1ODOHTWaLijx9PqXkJ+zHfaOBhja6rH9w53o0+gJLJRMmazOzfqDfJ+4dbtHRL2342NWIpFw+yUSFBQc4IqKvkVpaSlalD8hY2cOnlmfiueFqeiSf4+msoN4fUsGWtt+h8frhds7Aw8Jkg+JdjgTf1y9vpHKFuehuLgYhYXfoKTkEMqPHEFNrRTSUzKsS30ZT70gREqqCDcay/BG+las25wBSeUx9A1okonbp1zcJO3gg0y0/da5kRLv+xrHqqpxiMAqjlbiRK2Uq5PJcenSr5xCVvv42Q2pbIpwA6tuLmc/zd3Lfrn/E7aqoYFVq++y5sEhdmLSxoyOjcEyNLLSfa9XSO3LLUBLSzPKKipwUlqHM2frca7pPKf8uRXtKgVktcfxY9lh6G+2IxbwwK/7C0adBgbyGvhnRYAYGf0HeqOJVf/dLSShfI4TNXX47ofDKD9ajVNyBSc705Cs5y6oPO1XOibjq6t0JLpEr/z3kP6X5WjmMUevPXpEPyRaffDA5nC6HPd7+4cJMIV6a8suvLMtB+9mfoT0rL3YvHU393a6mFQxs3uPJIMin6r1l+cuNsoEykaFoPNis+Ba13WBlXYIorGYAIBgbW1NcKXjmuCW+jb1PwI/5wefrZbHAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Rebel Kronos",
                "title": "Rebel Kronos",
                "src": "/static/87e7cd96d1563875c8967470b498d4b9/37523/smiling-woman.png",
                "srcSet": ["/static/87e7cd96d1563875c8967470b498d4b9/5a46d/smiling-woman.png 300w", "/static/87e7cd96d1563875c8967470b498d4b9/0a47e/smiling-woman.png 600w", "/static/87e7cd96d1563875c8967470b498d4b9/37523/smiling-woman.png 720w"],
                "sizes": "(max-width: 720px) 100vw, 720px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </Grid>
      <Text variant="gigaBold" mdxType="Text">When using hashmarks and colours, keep in mind</Text>
      <ul>
        <li parentName="ul">{`Colour blocks go with the current product page it's meant for.`}</li>
        <li parentName="ul">{`Colour blocks and hashmarks should be balanced with the photo and text it's paired with. If the text is on the left of the image try to keep the colour block and hashmarks to the right of the photo.`}</li>
        <li parentName="ul">{`Use portrait dimensions for hero images only and landscape for the rest of the webpage. Print can have either, depending on the ratio of the print piece.`}</li>
        <li parentName="ul">{`Hashmarks have a stroke of 1pt.`}</li>
        <li parentName="ul">{`Hero images don't need hashmarks uploaded, they are automatically applied in the CMS.`}</li>
        <li parentName="ul">{`Always optimize images before uploading to the CMS, `}<a parentName="li" {...{
            "href": "https://tinypng.com"
          }}>{`https://tinypng.com`}</a>{` is great for this.`}</li>
      </ul>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      